import * as D from "dynein"
import { alertBox } from "./alertBox"

const $ = D.createSignal
const { div, h5, button, input, label } = D.elements

type ModalParams = {
	title: string,
	body: (closeModal:()=>void)=>void,
	footer?:(closeModal:()=>void)=>void,
	onclose?: ()=>void, size?: "xl" | "lg" | "sm",
	cornerCloseButton?: boolean,
	bodyStyle?: string
}
export default function modal({title, body, footer, onclose, size, cornerCloseButton = true, bodyStyle = ""}: ModalParams) {
	const owner = new D.Owner(null)

	const closeModal = ()=>{
		owner.destroy()
	}

	D.runWithOwner(owner, ()=>{
		D.addPortal(document.body, null, ()=>{
			div({class:"modal show", style:"display:block"}, ()=>{
				div({class:`modal-dialog ${size ? "modal-"+size : ""}`}, ()=>{
					div({class:"modal-content"}, ()=>{
						div({class:"modal-header"}, ()=>{
							h5({class:"modal-title"}, title)
							if (cornerCloseButton) {
								button({class:"btn-close", onclick:()=>{
									closeModal()
									if (onclose) {
										onclose()
									}
								}})
							}
						})
						div({class:"modal-body", style: bodyStyle}, ()=>{
							body(closeModal)
						})
						if (footer) {
							div({class:"modal-footer"}, ()=>{
								footer(closeModal)
							})
						}
					})
				})
			})
			div({class:"modal-backdrop show"})
		})
	})

	return owner
}

export function alertModal(title: string, message: string) {
	modal({
		title,
		body: ()=>{
			D.addText(message)
		},
		footer: (closeModal) => {
			button({class:"btn btn-primary", onclick: closeModal}, "OK")
		}
	})
}

export function promptModal(title: string, message: string, defaultValue: string = ""): Promise<string | undefined> {
	const value = $(defaultValue)

	return new Promise((resolve) => {
		modal({
			title,
			body: ()=>{
				const id = D.createUniqueId()
				label({class:"form-label", htmlFor: id}, message)
				const inputBox = input({class:"form-control", type:"text", value: value, id})
				requestAnimationFrame(()=>{
					inputBox.focus()
				})
			},
			onclose: ()=>{
				resolve(undefined)
			},
			footer: (closeModal) => {
				button({class:"btn btn-primary", onclick: ()=>{
					resolve(value())
					closeModal()
				}}, "OK")

				button({class:"btn btn-secondary", onclick: ()=>{
					resolve(undefined)
					closeModal()
				}}, "Cancel")
			}
		})
	})
}

export function confirmModal(title: string, message: string): Promise<boolean> {
	return new Promise((resolve) => {
		modal({
			title,
			body: ()=>{
				div({style:"white-space: pre-wrap"}, message)
			},
			onclose: ()=>{
				resolve(false)
			},
			footer: (closeModal) => {
				button({class:"btn btn-secondary", onclick: ()=>{
					resolve(true)
					closeModal()
				}}, "Yes")

				button({class:"btn btn-primary", onclick: ()=>{
					resolve(false)
					closeModal()
				}}, "Cancel")
			}
		})
	})
}

export function questionModal<T>(title: string, message: string, options: [T, string][]): Promise<T | undefined> {
	return new Promise((resolve) => {
		modal({
			title,
			body: ()=>{
				div({style:"white-space: pre-wrap"}, message)
			},
			onclose: ()=>{
				resolve(undefined)
			},
			footer: (closeModal) => {
				for (const [val, text] of options) {
					button({class:"btn btn-secondary", onclick: ()=>{
						resolve(val)
						closeModal()
					}}, text)
				}
			}
		})
	})
}
