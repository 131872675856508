import * as D from "dynein"
import { asyncGetCommandResultFilterError, setSubscription, tryCommand } from "./api"
import { Session, Basket, CMSAttribute, StatusDef, Product, ArticleTemplate, User, SerializedScoopSettings, BasketTreeNode, Article, Asset, NoteType } from "./types"
import { TextDocumentUtils } from "./editor/TextDocumentUtils"
import { WatchedArray, WatchedSet } from "@dynein/watched-builtins"

const $ = D.createSignal

export let session: Session | null = null
export let scoopSettings: Session["settings"]["ScoopEdit"] | null = null

export const loggedIn = D.createSignal(false)

export let allBaskets: Basket[] = []
export let basketPathToNode = new Map<string, BasketTreeNode>()
export let rootBasketNodes: BasketTreeNode[] = []

export const cmsPriorities = new Map<number, CMSAttribute>()
export const cmsPaywalls = new Map<number, CMSAttribute>()
export const printPriorities = new Map<number, CMSAttribute>()

export const onBasketsRefreshedSignal = D.createSignal(true, true)
export const focusedBasketPath = D.createSignal("")
export const getFocusedBasketNode = ()=>basketPathToNode.get(focusedBasketPath())

export const searchBasketName = "Search results"

export const statuses = new Map<number, StatusDef>()

export const showDeleted = $(false)

async function getStatuses() {
	const resp = await tryCommand({what: "status_get"}, "Failed to get statuses")
	if (!resp) {
		return
	}

	for (const status of (resp.statuses as StatusDef[])) {
		statuses.set(status.id, status)
	}
}

export const assetStatuses = new Map<number, StatusDef>()
async function getAssetStatuses() {
	const resp = await tryCommand({what: "asset_statuses_get"}, "Failed to get asset_statuses")
	if (!resp) {
		return
	}

	for (const status of (resp.statuses as StatusDef[])) {
		assetStatuses.set(status.id, status)
	}
}


export const products = new Map<number, Product>()
export let groups: string[] = []
export let sections: string[] = []
export let bloxProducts: number[] = []
export let wpProducts: number[] = []

async function getProducts() {
	const resp = await tryCommand({what: "products_get"}, "Failed to get products")
	if (!resp) {
		return
	}

	const groupsSet = new Set<string>()
	const sectionsSet = new Set<string>()
	for (const product of (resp.products as Product[])) {
		products.set(product.id, product)

		if (product.groups) {
			for (const group of product.groups) {
				groupsSet.add(group);
			}
		}

		if (product.sections) {
			for (const group of product.sections) {
				sectionsSet.add(group);
			}
		}
	}

	groups = Array.from(groupsSet)
	sections = Array.from(sectionsSet)

	for (const prod of resp.blox_products as string[]) {
		const id = parseInt(prod)
		if (id && !bloxProducts.includes(id)) {
			bloxProducts.push(id)
		}
	}

        for (const prod of resp.wp_products as string[]) {
            const id = parseInt(prod)
            if (id && !wpProducts.includes(id)) {
                wpProducts.push(id)
            }
        }
}

export let categories: string[] = []
async function getCategories() {
	const resp = await tryCommand({what: "categories_get"}, "Failed to get categories")
	if (!resp) {
		return
	}

	categories = resp.categories
}

export let defaultCharsPerColumnMeter = 0
async function getEditorSettings() {
	const resp = await tryCommand({what: "editor_get"}, "Failed to get editor settings")
	if (!resp) {
		return
	}

	defaultCharsPerColumnMeter = resp.chars_per_column_meter;
}

export const templates = new Map<number, ArticleTemplate>()
async function getTemplates() {
	const resp = await tryCommand({what: "article_templates_get_all"}, "Failed to get article templates")
	if (!resp) {
		return
	}

	for (const template of (resp.templates as ArticleTemplate[])) {
		if (template.editor_code != "ScEd") {
			continue;
		}

		if (!template.settings.chars_per_column_meter) {
			template.settings.chars_per_column_meter = defaultCharsPerColumnMeter;
		}

		template.tdu = new TextDocumentUtils(template.settings.styles);
		templates.set(template.id, template)
	}
}

export let defaultTemplateID = -1
async function getDefaultTemplate() {
	const resp = await tryCommand({what: "editors_get"}, "Failed to get editors")
	if (!resp) {
		return
	}

	for (const editor of resp.editors) {
		if (editor.code == resp.default_editor){
			defaultTemplateID = editor.default_article_template_id;
			break;
		}
	}

}

export let users: User[] = [];
async function getUsers() {
	const resp = await tryCommand({what: "user_list"}, "Failed to get user list")
	if (!resp) {
		return
	}


	users = resp.users
}

export let maxImageSize = 0;
async function getMaxImageSize() {
	const resp = await tryCommand({what: "get_max_img_size"}, "Failed to get max image size")
	if (!resp) {
		return
	}

	maxImageSize = resp.max_img_size
}


async function getCMSAttributes(which: "cms_priorities" | "cms_paywalls" | "print_priorities", targetMap: Map<number, CMSAttribute>, failureMessage: string) {
	const resp = await tryCommand({what: which+"_get"}, failureMessage)
	if (!resp) {
		return
	}

	const optionArr = resp[which] as CMSAttribute[]

	targetMap.clear()
	for (const option of optionArr) {
		targetMap.set(option.id, option)
	}
}


export async function refreshBaskets() {
	const resp = await tryCommand({what: "basket_get"}, 'Failed to refresh basket list')
	if (!resp) {
		return
	}

	allBaskets = resp.baskets

	basketPathToNode.clear()
	rootBasketNodes = []

	for (const basket of resp.baskets) {
		const path = (basket.parent ? (basket.parent+"/") : "") + basket.name

		const node: BasketTreeNode = {
			name: basket.name,
			path,
			basket,
			children: []
		}
		if (basketPathToNode.has(path)) {
			continue
		}
		basketPathToNode.set(path, node)
		if (basket.parent) {
			let parentNode = basketPathToNode.get(basket.parent)
			if (!parentNode) {
				// Cabinet/virtual basket
				parentNode = {
					name: basket.parent,
					path: basket.parent,
					children: []
				}
				basketPathToNode.set(basket.parent, parentNode)
				rootBasketNodes.push(parentNode)
			}

			parentNode.children.push(node)
		} else {
			rootBasketNodes.push(node)
		}
	}

	onBasketsRefreshedSignal(true)
}

export function getInitalStateAfterLogin(newSession: Session) {
	session = newSession
	scoopSettings = session.settings.ScoopEdit

	refreshBaskets()

	getStatuses();
	getAssetStatuses();
	getTemplates();
	getDefaultTemplate();
	getCategories();
	getProducts();
	getEditorSettings();

	getCMSAttributes("cms_priorities", cmsPriorities, "Failed to get CMS priorities")
	getCMSAttributes("cms_paywalls", cmsPaywalls, "Failed to get CMS paywalls")
	getCMSAttributes("print_priorities", printPriorities, "Failed to get Print Priorites")

	getUsers();

	getMaxImageSize()
}


export const focusedArticle = $<{id: number, version?: number} | null>(null)

export const generateArticleKey = (article: {id: number, version?: number} | null): string | null => {
	if (!article) {
		return null
	}

	if (article.version !== undefined) {
		return article.id+"-"+article.version
	} else {
		return article.id.toString()
	}
}

export const focusedArticleKey = ()=>generateArticleKey(focusedArticle())

export const selectedArticleIDs = new WatchedSet<number>()
export const selectedPrevArticleVersionIDsAndVersions = new WatchedSet<string>()
export const forceRefreshArticlesTableSignal = D.createSignal(true, true)
export const forceRefreshArticlesAssetsSignal = D.createSignal(true, true)

export const markedArticleKeys = new WatchedSet<string>()

export const rawXTagsMode = $(false)

setSubscription("notify_query_article_changed", ()=>{
	// TODO: differential updates
	forceRefreshArticlesTableSignal(true)
})

export const focusedArticleData = asyncGetCommandResultFilterError<Article>("Failed to get article data", ()=>{
	if (focusedArticle() == null) {
		return undefined
	}

	forceRefreshArticlesTableSignal()

	return {what: "article_get", id: focusedArticle()!.id, version: focusedArticle()!.version}
})

export const focusedArticleTemplate = ()=>{
	const article = focusedArticleData()
	if (article) {
		let template = templates.get(article.template_id)
		if (!template) {
			template = templates.get(defaultTemplateID)!
		}
		return template
	}
}

export const focusedArticleAssets = asyncGetCommandResultFilterError<{assets: Asset[]}, Asset[]>("Failed to get article assets", ()=>{
	if (focusedArticle() == null) {
		return undefined
	}

	forceRefreshArticlesAssetsSignal()

	return {what: "assets_get", id: focusedArticle()!.id, preview: true}
}, raw => raw.assets)

export const tryingToSelectAsset = $<string | null>(null)

export const focusedArticleFacts = asyncGetCommandResultFilterError<{text: string}, string>("Failed to get article facts", ()=>{
	if (focusedArticle() == null) {
		return
	}

	if (focusedArticle()!.version) {
		return
	}

	forceRefreshArticlesTableSignal()

	return {what: "note_get", id: focusedArticle()!.id, num: NoteType.FACT}
}, raw => raw.text)


export const focusedArticleSummary = asyncGetCommandResultFilterError<{text: string}, string>("Failed to get article summary", ()=>{
	if (focusedArticle() == null) {
		return
	}

	if (focusedArticle()!.version) {
		return
	}

	forceRefreshArticlesTableSignal()

	return {what: "note_get", id: focusedArticle()!.id, num: NoteType.SUMMARY}
}, raw => raw.text)
