/*
!!WARNING!!

Any changes made to this file will impact all users of WebEdit.
Ensure any changes are backwards compatible!

!!WARNING!!
*/

import * as D from "dynein"
import { socketError, command, socketReady, openSocket, attemptLogin } from "../api"
import { alertBox } from "../components/alertBox"
import { Session, SerializedScoopSettings } from "../types"
import { recordToMap } from "../utils/recordToMap"
import { getInitalStateAfterLogin, loggedIn } from "../state"

const { div, input, button, span, nav, ul, li } = D.elements
const $ = D.createSignal
const $text = D.addText

const savedSession: Session | null = JSON.parse(localStorage.getItem("scoop-savedSession") ?? "null")

export function signOut() {
	localStorage.setItem("scoop-savedSession", "null")
	location.reload()
}

export function loginPage() {
	div({style:`
		position: fixed;
		inset: 0;
		background: #f16b2e url('/WebEdit/static/images/ScoopSplash.png');
		background-size: 100%;
		background-position-y: 50%;

		user-select: none;
	`})

	const loginError = $("")
	const sessionResumeError = $("")
	if (savedSession && savedSession.expires > Date.now()) {
		sessionResumeError("Session expired. Please sign in again.")
	}

	const serverAddress = $(localStorage.getItem("scoop-serverAddress") ?? "")
	const usernameSignal = $(localStorage.getItem("scoop-autologinUsername") ?? "")
	const passwordSignal = $("")

	const tryingLogin = $(false)
	const tryLogin = () => {
		if (tryingLogin()) {
			return
		}

		tryingLogin(true)
		loginError("")
		socketError("")
		sessionResumeError("")

		localStorage.setItem("scoop-autologinUsername", usernameSignal())
		localStorage.setItem("scoop-serverAddress", serverAddress())

		try {
			attemptLogin(serverAddress()).then(() => {
				logInAndInit()
			}).catch(() => {
				attemptLogin(serverAddress(), false).then(() => {
					logInAndInit()
				}).catch(() => {
					tryingLogin(false)
				})
			})
		} catch (err: any) {
			tryingLogin(false)
			loginError(err.message)
		}
	}

	const logInAndInit = () => {
		if (socketError() || sessionResumeError()) {
			return
		}

		command({
			what: "login",
			username: usernameSignal().toLowerCase(),
			password: passwordSignal(),
			machine_name: "WebEdit ( " + navigator.platform + ")"
		}).then((resp) => {
			tryingLogin(false)
			initSession(resp)
		}).catch((err: Error) => {
			tryingLogin(false)
			loginError(err.message)
		})
	}

	const trySessionResume = () => {
		console.log("trySessionResume")
		if (!savedSession) {
			throw new Error("trySessionResume called without saved session")
		}

		if (socketError() || loginError()) {
			return
		}

		try {
			command({
				what: "session_resume",
				username: savedSession.name,
				sessionid: savedSession.sessionid
			}).then((resp) => {
				initSession(resp)
			}).catch((err: Error) => {
				sessionResumeError(err.message)
			})
		} catch (err: any) {
			sessionResumeError(err.message)
		}
	}

	const initSession = (session: Session) => {
		const fetchedSettings: SerializedScoopSettings = session.settings.ScoopEdit ?? {}
		session.settings.ScoopEdit = {
			watchedBaskets: recordToMap(fetchedSettings.watched ?? {}),
			watchedSearches: recordToMap(fetchedSettings.watchedSearches ?? {}),
			autosaveChar: fetchedSettings.autosaveChar ?? 100,
			autosaveMin: fetchedSettings.autosaveMin ?? 100,
			rulerUnits: fetchedSettings.rulerUnits
		}

		// This isn't the best thing security-wise, but it's probably alright for now. See https://stackoverflow.com/a/44209185
		localStorage.setItem("scoop-savedSession", JSON.stringify({name: session.name, sessionid: session.sessionid}))

		command({
			what: "scoop_version"
		}).then((resp) => {
			let href = window.location.href
			let version = resp["version"].split("-")[0]
	
			if (!href.includes(version)) {
				window.location.href = window.location.origin + `/WebEdit/${version}/index.html`
			}
	
			getInitalStateAfterLogin(session)
			loggedIn(true)
		}).catch((err: Error) => {
			loginError(err.message)
			loggedIn(false)
		})
	}

	div({style:"position: fixed; top: 0; right: 0; bottom: 0; width: 40%; display: flex; align-items: center; justify-content: center; z-index: 20000;"}, ()=>{
		div({class:"d-grid gap-2", style:"width: 90%; max-width: 400px;"}, ()=>{
			D.addIf(()=>!socketError() && !sessionResumeError() && !loginError() && savedSession && serverAddress(), ()=>{
				if (tryingLogin()) {
					return
				}

				tryingLogin(true)

				attemptLogin(serverAddress()).then(() => {
					tryingLogin(false)
					trySessionResume()
				}).catch(() => {
					attemptLogin(serverAddress(), false).then(() => {
						tryingLogin(false)
						trySessionResume()
					}).catch(() => {
						tryingLogin(false)
					})
				})
			}).else(()=>{
				const mergedError = ()=>socketError() || sessionResumeError() || loginError()
				D.addIf(()=>mergedError(), ()=>{
					alertBox(()=>mergedError())
				})

				const keydownListener = (evt: KeyboardEvent) => {
					if (evt.key === "Enter") {
						tryLogin();
					}
				}
				
				input({class:"form-control", type:"text", placeholder: "Server Address", value:serverAddress, onkeydown: keydownListener})
				input({class:"form-control", type:"text", placeholder: "Username", value:usernameSignal, onkeydown: keydownListener})
				input({class:"form-control", type:"password", placeholder: "Password", value:passwordSignal, onkeydown: keydownListener})

				button({class:"btn btn-outline-primary", onclick: tryLogin, disabled: tryingLogin}, ()=>{
					D.addIf(tryingLogin, ()=>{
						div({class:"spinner-border"})
					}).else(()=>{
						$text("Login")
					})
				})
			})
		})
	})
}
