import * as D from "dynein"
import modal, { alertModal } from "./components/modal"
import { alertBox } from "./components/alertBox"

const $ = D.createSignal

const messageSubscriptions = new Map<string, (msg: any)=>void>()

export const socketReady = $(false)
export const socketError = $("")

let socket: WebSocket | null = null



const commandCallbacks = new Map<string, (msg: any)=>void>()

let messageCounter = 0;

export async function command(obj: any): Promise<any> {
	if (!obj.what) {
		throw new Error("ScoopClient: command() called with no 'what'")
	}
	if (!socket) {
		throw new Error("ScoopClient: command() called before openSocket()")
	}
	if (socket.readyState !== socket.OPEN) {
		throw new Error("ScoopClient: command() called while socket not open")
	}

	messageCounter = messageCounter % 60000;
	obj.seq = messageCounter++;
	return new Promise((resolve, reject)=>{
		commandCallbacks.set(obj.what + "@" + obj.seq, (resp)=>{
			if (resp.error) {
				let errorMsg = resp.error
				if (resp.args) {
					for (let i = 0; i<resp.args.length; i++) {
						errorMsg = errorMsg.replace(new RegExp('%' + Math.abs(i + 1), 'gm'), resp.args[i]);
					}
				}
				reject(new Error(errorMsg))
			} else {
				resolve(resp)
			}
		})

		console.log("<<<", obj)
		socket!.send(JSON.stringify(obj))
	})
}

export async function tryCommand<T = Record<string, any>>(obj: any, failureMessage: string): Promise<T | undefined> {
	try {
		return await command(obj)
	} catch (err: any) {
		console.log(err)
		alertModal(failureMessage, err.message)
	}
}

export function asyncGetCommandResult<T = Record<string, any>>(query: ()=>any): ()=>null | undefined | Error | T {
	let currentRun = 0

	let resultRun = 0
	const result = D.createSignal<null | Error | undefined | T>(null)

	async function tryGetResult(obj: any, run: number) {
		if (!obj) {
			result(undefined)
			return
		}
		try {
			setTimeout(()=>{
				if (resultRun !== currentRun) {
					result(undefined)
				}
			}, 100)
			const resp = await command(obj)
			if (run === currentRun) {
				resultRun = currentRun
				result(resp)
			}
		} catch (err: any) {
			if (run === currentRun) {
				resultRun = currentRun
				result(err)
			}
		}
	}

	D.createEffect(()=>{
		currentRun++
		const latestQuery = query()

		D.untrack(()=>{
			tryGetResult(latestQuery, currentRun)
		})
	})

	return result
}

export function asyncGetCommandResultFilterError<T = Record<string, any>>(failureMessage: string, query: ()=>any): ()=>null | undefined | T
export function asyncGetCommandResultFilterError<T, U>(failureMessage: string, query: ()=>any, map: (raw: T)=>U): ()=>null | undefined | U
export function asyncGetCommandResultFilterError(failureMessage: string, query: ()=>any, map: (raw: any)=>any = (r => r)): ()=>any {
	const resultMaybeError = asyncGetCommandResult(query)

	return ()=>{
		const val = resultMaybeError()
		if (!val) {
			return undefined
		}
		if (val instanceof Error) {
			alertModal(failureMessage, val.message)
			return undefined
		}
		return map(val)
	}
}

export function attemptLogin(serverAddress: string, secure: boolean = true, attempts: number = 3): Promise<void> {
	return new Promise((resolve, reject) => {
		openSocket(serverAddress, secure).then(() => {
			resolve()
		}).catch(() => {
			if (--attempts > 0) {
				attemptLogin(serverAddress, secure, attempts).then(() => {
					resolve()
				}).catch(() => {
					reject()
				})
			} else {
				reject()
			}
		})
	})
}

export function openSocket(serverAddress: string, secure: boolean = true): Promise<void> {
	socketError("")

	const websocketURI = `${secure ? "wss" : "ws"}://${serverAddress}/WebEdit/ws`

	console.log("opening socket to "+websocketURI)

	return new Promise((resolve, reject)=>{
		socket = new WebSocket(websocketURI);

		socket.onopen = ()=>{
			socketReady(true)
			resolve()
		}

		socket.onerror = (e)=>{
			socketError("Failed to connect to the Scoop server.");
			console.error(e);
			reject()
		};

		socket.onmessage = function (event) {
			let msg: any
			try {
				msg = JSON.parse(event.data);
			} catch (e) {
				console.error(e);
				return;
			}
			console.log(">>>", msg)

			let replyto = msg.replyto;
			if (msg.seq !== undefined) {
				replyto += "@" + msg.seq;
			}

			if (commandCallbacks.has(replyto)) {
				const callback = commandCallbacks.get(replyto)!
				commandCallbacks.delete(replyto)
				try {
					callback(msg);
				} catch (e) {
					console.error(e);
				}
			} else if (messageSubscriptions.has(replyto)) {
				try {
					messageSubscriptions.get(replyto)!(msg)
				} catch (e) {
					console.error(e);
				}
			} else {
				console.warn(`ScoopClient: Got message what:'${msg.what}',replyto:'${replyto}' with no handler.`);
			}
		};

		socket.onclose = function (e) {
			if (socketReady()) {
				socketError("The Scoop server has closed the connection.");
			}
		};
	})
}

export function setSubscription(replyto: string, fn: (msg: Record<string, any>)=>void) {
	if (messageSubscriptions.has(replyto)) {
		throw new Error(`A subscription has already been defined for "${replyto}".`)
	}
	messageSubscriptions.set(replyto, fn)
}
