import * as D from "dynein"

function escapeTextForHTML(text: string, preserveSpaces: boolean=false): string {
	const tmp = document.createElement("div")
	tmp.textContent = text
	let html = tmp.innerHTML
	if (preserveSpaces)
		html = html.replace(/  /g, ' &nbsp;').replace(/^ /, '&nbsp;');
	return html;
}
function escapeTextForXTags(text: string) {
	return text.replace(/\n/g, "<\\n>")
		.replace(/\u2028/gm, "<\\n>")
		.replace(/\</g, "<\\<>")
		.replace(/@/g, "<\\@>");
}
function escapeLinkHref(href: string) {
	return href.replace(/\)/g, "%29").replace(/\\/g, "%5C").replace(/>/g, "%3E");
}

export function styleNameToClassName(styleName: string) {
	if (!styleName)
		return styleName;
	return "style-" + styleName.replace(/[^a-zA-Z0-9]/g, function (s) {
		var c = s.charCodeAt(0);
		if (c === 32)
			return '-'; // 32 = " "
		return '_' + c + "_";
	});
}
export function classNameToStyleName(className: string | undefined) {
	if (!className)
		return className;
	return className
		.substring('style-'.length)
		.replace(/-/g, " ")
		.replace(/_(\d+)_/g, function (s, c) {
			return String.fromCharCode(parseInt(c));
		});
}

export function styleClassNameOf(node: HTMLElement) {
	var styleClass = undefined;
	for (const className of node.classList) {
		if (className.startsWith('style-')) {
			styleClass = className;
			break;
		}
	}
	return styleClass;
}

export function isXTagsDocumentEmpty(text: string): boolean {
	if (!text) {
		return true
	}

	return (text[0] === "@") && (text[text.length-1] === ":") && !text.includes("\n") && (Array.from(text.matchAll(/:/g)).length === 1)
}

function normalizeHref(href: string) {
	if (!href)
		return "";

	href = href.trim();

	if (!href)
		return "";

	if (!/^[a-z0-9]+:/i.test(href))
		href = "http://" + href;

	return href;
}

type HTMLToXTagsConverterState = {
	characterStyle?: string,
	lastParagraphStyle?: string,
	inInlineNote?: boolean
}

function convertNodeToXTags(node: Node, state: HTMLToXTagsConverterState, style?: string) {
	var xtags = '';
	if (node.nodeType === Node.TEXT_NODE && node.textContent) {
		if (style && state.characterStyle != style) {
			if (style == state.lastParagraphStyle)
				xtags += "<@$p>";
			else
				xtags += "<@" + style + ">";
			state.characterStyle = style;
		}

		xtags += escapeTextForXTags(node.textContent);
		return xtags;
	} else if (node.nodeType !== Node.ELEMENT_NODE) {
		return xtags;
	} else {
		const el = node as HTMLElement
		// Filter out some elements we want to ignore.
		if (el.tagName == "STYLE")
			return xtags;

		if (el.tagName == "P") {
			xtags += convertParagraph(el, state);
		} else if (el.tagName == "SPAN" || el.tagName == "A") {
			xtags += convertSpanOrA(el, state);
		} else if (el.tagName == "BR") {
			if (el.className !== "ProseMirror-trailingBreak") {
				xtags += "<\\n>";
			}
		} else if (el.tagName == "IMG") {
			// Special character.
			if ((el as HTMLImageElement).src.includes("FrameBreakCharacter")) {
				xtags += "<\\b>";
			} else if ((el as HTMLImageElement).src.includes("ColumnBreakCharacter")) {
				xtags += "<\\c>";
			}
		}
		return xtags;
	}
}
function convertSpanOrA(node: HTMLElement, state: HTMLToXTagsConverterState) {
	if (!node.innerText)
		return ''; // Skip entirely.

	var xtags = '', closers = '';
	if (node.classList.contains('inline-note')) {
		xtags += "<!>";
		closers += "</!>";
	} else if (node.tagName == "A") {
		xtags += "<link(" + escapeLinkHref((node as HTMLAnchorElement).href) + ")>";
		closers += "</link>";
	}

	var style = classNameToStyleName(styleClassNameOf(node));
	if (!style)
		style = state.characterStyle;

	for (const childNode of node.childNodes)
		xtags += convertNodeToXTags(childNode, state, style!);

	xtags += closers;
	return xtags;
}
function convertParagraph(pNode: HTMLElement, state: HTMLToXTagsConverterState) {
	var xtags = '', style = classNameToStyleName(styleClassNameOf(pNode));
	if (!style)
		style = "Normal";
	if (style != state.lastParagraphStyle) {
		xtags += "@" + style + ":";
		state.lastParagraphStyle = state.characterStyle = style;
	}

	for (const node of pNode.childNodes)
		xtags += convertNodeToXTags(node, state, style);

	xtags += "\n";
	return xtags;
}

export type SerializedStyleDef = {
	config: string,
	name: string,
	type: "Character" | "Paragraph"
}

export type StyleDef = {
	config: {
		bold: boolean,
		font: string,
		italic: boolean,
		underline: boolean,
		size: number,
		alignment?: number,
		color?: string
		leading: number,
		firstIndent?: number,
		nextStyle?: string,
		shortcut?: string,
		indent: number
		lineHeight: number,
		marginBottom: number,
		measure: number
	},
	name: string,
	kind?: string,
	type: "Character" | "Paragraph"

	missing?: boolean
}

type XTagsRenderResult = {
	html: string,
	missingParagraphStyles: Set<string>
	missingCharacterStyles: Set<string>
}

export class TextDocumentUtils {
	styles: Map<string, StyleDef>

	css: string

	constructor(styles: SerializedStyleDef[]) {
		this.styles = new Map()
		this.css = "";

		// Initialize.
		for (const style of styles) {
			const newStyle: any = Object.assign(Object.create(null), style);
			newStyle.config = JSON.parse(newStyle.config);
			this.styles.set(newStyle.name, newStyle)

			this.css += this.convertStyleToCSS(newStyle);
		}
	}

	classNameToStyle(className: string): StyleDef | null {
		return this.styles.get(classNameToStyleName(className)!) ?? null
	}

	convertStyleToCSS(style: StyleDef) {
		var styleStr, config = style.config,
			align = ["left", "center", "right", "justify"];

		if (style.type == "Character")
			styleStr = "span";
		else
			styleStr = "p";

		styleStr += ('.' + styleNameToClassName(style.name) + "{ ");

		if (config.bold)
			styleStr += "font-weight: bold; "
		else
			styleStr += "font-weight: normal; "

		styleStr += "font-family: " + config.font + "; ";

		if (config.italic)
			styleStr += "font-style: italic; ";
		else
			styleStr += "font-style: normal; ";

		styleStr += "font-size: " + config.size + "pt; ";

		if (config.underline)
			styleStr += "text-decoration: underline; ";
		else
			styleStr += "text-decoration: none; ";

		if ("color" in config && config.color != "#000000")
			styleStr += "color: " + config.color + "; ";

		if ("alignment" in config)
			styleStr += "text-align: " + align[config.alignment ?? 0] + "; ";

		if (config.firstIndent)
			styleStr += "text-indent: " + config.firstIndent + "mm; ";

		if (config.indent)
			styleStr += "margin-left: " + config.indent + "mm; ";

		if (config.lineHeight)
			styleStr += "line-height: " + config.lineHeight + "mm !important; ";

		if (config.marginBottom)
			styleStr += "margin-bottom: " + config.marginBottom + "mm !important; ";

		if (config.measure)
			styleStr += "max-width: " + config.measure + "mm; ";

		styleStr += "}\n";
		return styleStr;
	}

	convertXTagsToHTML(xtags: string): XTagsRenderResult {
		// Break the input into an array of lines, each will be made into a paragraph
		let lines = xtags.split(/\r\n|\r|\n/gm)
		let firstParagraph = true
		let inNote = false
		let inLink = false
		let unclosedCharTag: string | false = false
		let output = ""
		let current = ""

		const missingParagraphStyles = new Set<string>()
		const missingCharacterStyles = new Set<string>()

		for (const line of lines) {
			var ibegin = 0, forceNewParagraph = false;

			if (line.startsWith("@")) {
				var styleStart = 1;
				if (firstParagraph && line.startsWith("@:@"))
					styleStart = 3;

				ibegin = line.indexOf(':', styleStart - 1) + 1;

				const newStyle = line.substring(styleStart, ibegin - styleStart);
				current = styleNameToClassName(newStyle);

				if (!this.styles.has(newStyle) || this.styles.get(newStyle)!.type === "Character") {
					missingParagraphStyles.add(newStyle)
					current += " missing-paragraph-style"
				}

				unclosedCharTag = false;
			}
			firstParagraph = false

			var result = "<p class='" + current + "'>";

			if (inNote)
				result += '<span class="inline-note">';
			if (unclosedCharTag)
				result += '<span class="' + unclosedCharTag + '">';

			for (var i = ibegin; i < line.length; i++) {
				// Character styles have the syntax < @ stylename >
				// Special escape codes, \ to <\\>, @ to <\@>

				// If there is a single '<' (which is invalid syntax) at the end of the line,
				// handle that case here, so we do not do an out of bounds access later on.
				var next = line.indexOf('<', i),
					end = (next == -1) ? -1 : line.indexOf('>', next);
				if (end == -1)
					next = -1;

				// Insert text until the next code, if any.
				result += escapeTextForHTML(line.substring(i, (next == -1) ? line.length : next), true)
					.replace(/\u2028/gm, '<br>');
				if (next == -1)
					break;

				i = next;
				next = end;
				i++;

				const interior = line.substring(i, next);
				switch (line.charAt(i)) {
				case '\\': { // Escaped character
					var str = line.substring(i + 1, next);
					i = next;

					if (str == "b") {
						// Frame break.
						result += '<img class="character frame-break-marker" src="/WebEdit/static/images/FrameBreakCharacter.png">';
					} else if (str == "c") {
						// Column break.
						result += '<img class="character column-break-marker" src="/WebEdit/static/images/ColumnBreakCharacter.png">';
					} else if (str == "n") {
						// Line break (not paragraph break.)
						result += "<br>";
					} else {
						result += escapeTextForHTML(str);
					}
				} break;

				case '@': { // Character style
					var styleName = line.substring(i + 1, next);

					if (styleName == "$p") {
						result += "</span>";
						unclosedCharTag = false;
					} else {
						// If two tags are back to back, close the first.
						if (unclosedCharTag)
							result += "</span>";



						let className = styleNameToClassName(styleName)
						if (!this.styles.has(styleName) || this.styles.get(styleName)!.type === "Paragraph") {
							missingCharacterStyles.add(styleName)
							className += " missing-character-style"
						}

						unclosedCharTag = className
						result += '<span class="' + className + '">';
					}
					i = next;
				} break;

				case '!': { // Inline note
					if (unclosedCharTag)
						result += "</span>";

					result += '<span class="inline-note">';

					if (unclosedCharTag)
						result += '<span class="' + unclosedCharTag + '">';

					inNote = true;
				} break;
				case "l": {
					if (interior.startsWith("link(")) {
						let href = interior.slice(5, -1);
						href = normalizeHref(href);
						if (!href)
							break;

						if (unclosedCharTag)
							result += "</span>";

						const tmpA = document.createElement("a")
						tmpA.setAttribute("href", href)
						tmpA.setAttribute("title", href)

						result += tmpA.outerHTML.replace("</a>","");

						if (unclosedCharTag)
							result += '<span class="' + unclosedCharTag + '">';

						inLink = true;
					}
				} break;
				case '/': {
					if (line.charAt(i + 1) == '!') { // End of inline note.
						if (unclosedCharTag)
							result += '</span>';

						result += '</span>';

						if (unclosedCharTag)
							result += '<span class="' + unclosedCharTag + '">';

						inNote = false;
					} else if (interior == "/link") { // End of link.
						if (unclosedCharTag)
							result += '</span>';

						result += '</a>';

						if (unclosedCharTag)
							result += '<span class="' + unclosedCharTag + '">';

						inLink = false;
					}
				} break;

				default:
					console.warn("TextDocumentUtils: unrecognized escaped char "
						+ line.charAt(i));
				break;
				}
				i = next;
			}

			if (inLink)
				result += "</a>";
			if (inNote)
				result += "</span>";
			if (unclosedCharTag)
				result += "</span>";

			result += "</p>";
			output += result;
		}
		return {
			html: output,
			missingParagraphStyles,
			missingCharacterStyles
		};
	}

	convertHTMLToXTags(root: HTMLElement) {
		var xtags = '', state: HTMLToXTagsConverterState = {inInlineNote: false};
		for (const node of root.childNodes)
			xtags += convertNodeToXTags(node, state, undefined);
		return xtags.trimEnd();
	}

	addStylesToHead() {
		D.addPortal(document.head, ()=>{
			const style = document.createElement("style")
			style.textContent = this.css
			D.addNode(style)
		})
	}
}
