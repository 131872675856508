import * as D from "dynein"

const { div, input, button, span, nav, ul, li } = D.elements
const $ = D.createSignal
const $text = D.addText

export function alertBox(msg: string | (()=>string)) {
	div({class:"alert alert-danger"}, ()=>{
		$text(msg)
	})
}
